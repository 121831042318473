<template>
    
    <div class="sponopLogo">
        <div class="sponopSfake">
            <div class="circleSponop"/>
        </div>
        <div class="sponopS1">
            <div class="circleSponopHalf1"/>
            <div class="sStroke"/>
        </div>
        <div class="sponopS2">
            <div class="circleSponopHalf2"/>
        </div>
        <div class="sponopP1">
            <div class="circleSponop"/>
            <div class="pbox"/>
        </div>
        <div class="sponopO1">
            <div class="circleSponop"/>
        </div>
        <div class="sponopN">
            <div class="circleSponopHalfN"/>
            <div class="sponopNfake"/>
            <div class="nboxl"/>
            <div class="nboxr"/>
        </div>
        <div class="sponopO2">
            <div class="circleSponop"/>
        </div>
        <div class="sponopP2">
            <div class="circleSponop"/>
            <div class="pbox"/>
        </div>
    </div>

    <footer>
        <button class="footerButton"><a class="stylelessLink" href="/impressum.html">Impressum</a></button>
    </footer>
</template>

<script setup>
    import { onMounted } from 'vue' //ref,...

    // reactive state
    //const count = ref(0)

    // functions that mutate state and trigger updates

    // lifecycle hooks
    onMounted(() => {
        console.log("Sponop")
    })
</script>

<style scoped>
    @import "sponopAnimation.css";
</style>

