<template>
  <SponopAnimation/>
</template>

<script>
import SponopAnimation from './components/SponopAnimation.vue'


export default {
  name: 'App',
  components: {
    SponopAnimation
  }
}
</script>

<style>
@font-face {
    font-family: "PlusJakartaSans";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("PlusJakartaSans"),
        url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff2") format("woff2"),
        url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff") format("woff");
}

@font-face {
    font-family: "PlusJakartaSans-Light";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("PlusJakartaSans"),
        url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.woff2") format("woff2"),
        url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "Monocode";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Monocode"),
        url("./assets/fonts/Monocode/monocode-regular.ttf") format("truetype")
}

@font-face {
    font-family: "Monocode-Light";
    font-weight: 100;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Monocode"),
        url("./assets/fonts/Monocode/monocode-light.ttf") format("truetype")
}


#app {
  font-family: "PlusJakartaSans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
